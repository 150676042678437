<template>
    <div id="app">
        <router-view></router-view>
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>
<script>
    export default {
        components:{

        },
        name: "app"
    }
</script>

<style lang="less">
    html,body,#app{
        width: 100%;
        font-size: 14px;
        height: 100%;
        background-color: #f7f7f7;
    }
</style>
