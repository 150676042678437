import axios from 'axios';
import app from '@/main';
import store from '@/vuex/index';
import {Message} from "element-ui";
import storage from "@/utils/storage";
import * as helper from './aes';


let elseConfig = {
    need: {
        error: false,
        loading: true,
        progress: true,
    },
    default:{
        error: false,
        loading: true,
        progress: true,
    }
};

export default (baseUrl, config) => {
    let baseConfig = Object.assign({}, {
        baseURL: baseUrl,
        timeout: 8000,
        responseType: process.env.VUE_APP_ENCODE_DATA? '' : 'json',
    }, config || {});
    let request = axios.create(baseConfig);
    request.interceptors.request.use(config => {
        //每次先还原数据
        elseConfig.need=JSON.parse(JSON.stringify(elseConfig.default));
        if (config.need){
            for (let k in config.need){
                if (typeof config.need[k]==='boolean'){
                    elseConfig.need[k]=config.need[k];
                }
            }
        }
        if (elseConfig.need.progress){
            app.$Progress.start();
        }
        if (elseConfig.need.loading){
            store.dispatch('loading', true);
        }
        //增加token的内容
        config.headers.auth = storage.token() || '';
        if (config.url.indexOf('/bbs/') === 0 || config.url.indexOf('/app/') === 0||config.url.indexOf('/bigmap/') === 0) {
            config.url += '.bb';
        }
        if (config.baseURL===process.env.VUE_APP_UPLOAD_HOST){
            //如果是在上传图片，因为是上传到了img 所以这里要单独处理一次
            config.headers.upload = storage.storage(process.env.VUE_APP_UPLOAD_CODE) || '';
        }
        if (config.method === 'get') {
            if (!config.params) config.params = {_t: Math.random()};
            config.params._t = Math.random();
        }else{
            if (config.baseURL!==process.env.VUE_APP_UPLOAD_HOST){
                //非上传的时候，才可以加密
                config.headers['Content-Type']='text/plain';
                if (process.env.VUE_APP_ENCODE_DATA){
                    if (!config.data) config.data={};
                    let token=storage.token();
                    config.data._token=token;
                    config.data._t=Date.parse(new Date())/1000;
                    config.data=helper.encode(JSON.stringify(config.data),helper.getKey(token));
                }
            }
        }
        // if (config.method==='delete'){
        //     console.log(config);
        // }
        if (config.baseURL === process.env.VUE_APP_HISTORY_HOST) {
            config.headers.bigemap = '1';
        }
        return config;
    }, error => {
        app.$Progress.fail();
        store.dispatch('loading', false);
        Message.error('请求出错了');
        return Promise.reject(error);
    });

    request.interceptors.response.use(data => {
        if (elseConfig.need.progress){
            app.$Progress.finish();
        }
        store.dispatch('loading', false);
        if (process.env.VUE_APP_ENCODE_DATA&&data.config.baseURL!==process.env.VUE_APP_UPLOAD_HOST){
            //非上传的时候，才可以解决
            let token=storage.token();
            try {
                data.data=helper.decode(data.data,helper.getKey(token));
            }catch (e) {
                //如果出错了，那么要重新登录
                storage.clear();
                location.href='/';
                return;
            }
        }
        if (!data.data || data.data.code !== 200) {
            let code = data.data && data.data.code;
            // console.log('222');
            // console.log(data.data);
            switch (code) {
                case 4001:
                    storage.clear();
                    // app.$router.push({path: '/login'});
                    break;
            }
            //系统中的很多地方不需要有错误提示，这里全局禁用了，只有在需要的时候才会手动展示
            if (elseConfig.need.error){
                Message.error(data.data && (data.data.message || data.data.msg) || '遇到未知错误!');
            }
            return Promise.reject(data.data && (data.data.message || data.data.msg) || '遇到未知错误!');
        }
        return data.data;
    }, (error) => {
        if (elseConfig.need.progress){
            app.$Progress.fail();
        }
        store.dispatch('loading', false);
        // Message.error('服务器在打盹，请稍后重试!');
        return Promise.reject(error)
    });
    return request;
};
