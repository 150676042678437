import * as CryptoJS from 'crypto-js';
export const encode = (text,key) => {
    // 将密钥转换为WordArray对象
    const keyWordArray = CryptoJS.enc.Utf8.parse(key);
    // 对明文进行AES-128-ECB加密
    const encrypted = CryptoJS.AES.encrypt(text, keyWordArray, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    // 返回加密后的密文，通常以Base64编码
    return encrypted.toString();
}

export const decode =(content,key)=>{
    // 将密钥转换为 WordArray 对象
    const keyWordArray = CryptoJS.enc.Utf8.parse(key);
    // 对密文进行 AES-256-ECB 解密
    const decrypted = CryptoJS.AES.decrypt(content, keyWordArray, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 256 / 32 // 使用 256 位密钥
    });
    // 将解密后的结果转换成 UTF-8 编码的字符串
    let result=decrypted.toString(CryptoJS.enc.Utf8);
    try {
        result=JSON.parse(result);
    }catch (e){
        return  '';
    }
    return result;
}

export const getKey=(str)=>{
    // if (!str) str='';
    //永远使用0000，这样就不会有业务因为登陆导致无法解密
    str='';
    if (str.length<16){
        str+='0'.padEnd(16,'0');
    }
    return str.substring(0,16);
}
